body {
  margin: 0;
  background-color: #efefef;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.node a > svg {
  margin-right: 1rem;
}
.node div select {
  height: 19px !important;
}

.import-json-button {
  display: none !important;
}

svg {
  overflow: visible !important;
}

p {
  overflow-wrap: break-word;
}

.jsonTreeContainer > ul {
  background-color: #212121 !important;
}

.rfipdropdown {
  position: static !important;
}

li.MuiListItem-button {
  height: 36px;
}
